$('.sliderReferences').owlCarousel({
    loop: true,
    dots: true,
    margin: 0,
    nav: true,
    auto: true,
    itemElement: 'blockquote',
    navText: ['', ''],
    mouseDrag: true,
    autoplay: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        830: {
            items: 1
        },
        1600: {
            items: 1
        },
        1920: {
            items: 1
        },
        2220: {
            items: 1
        }
    }
});
$('.slider-clients').owlCarousel({
    loop: true,
    margin: 0,
    dots: false,
    nav: false,
    auto: true,
    itemElement: 'div',
    navText: ['', ''],
    mouseDrag: true,
    autoplay: true,
    smartSpeed: '400',
    autoplayTimeout: '3000',
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        830: {
            items: 3
        },
        1600: {
            items: 4
        },
        1920: {
            items: 5
        }
    }
});