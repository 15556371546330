jQuery(document).ready(function ($) {

    var $w = $(window),
        $canvases = $('body');
    var nav = document.querySelector("#nav");
    var leap = document.querySelector(".leapRight");

    $w.scroll(function () {
        var d = $w.height() / 2 + $w.scrollTop();
        $canvases.each(function () {
            var $this = $(this),
                ptop = $this.offset().top,
                total = ptop + $this.height();
            if (d < 750) {
                nav.classList.remove("animated", "fadeIn", "navbar-fixed");
                leap.classList.remove("animated", "fadeIn", "fixed");
            }
            else {
                nav.classList.add("animated", "fadeIn", "navbar-fixed");
                leap.classList.add("animated", "fadeIn", "fixed");
            }
            $this.toggleClass('active', ptop < d && total >= d);
        });
    }).scroll(); //trigger the handler when DOM is ready

    $('[data-target="#navbar"]').click(function () {
        if ($(this).attr('aria-expanded') === 'true') {
            $('body').css('overflow', 'scroll');
            $('body').css('position', 'relative');
            $(this).css('position', 'relative');
            $(this).css('top', '7px');

        }
        else if ($(this).attr('aria-expanded') === 'false') {
            $('body').css('overflow', 'hidden');
            $('body').css('position', 'fixed');
            $(this).css('position', 'fixed');
            $(this).css('top', '22px');
        }
    });

});