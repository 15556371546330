jQuery(document).ready(function ($) {

    if (document.getElementById("radial-progress")) {

        var transform_styles = ['-webkit-transform', '-ms-transform', 'transform'];
        var fill_circle = function () {
            var rotation = 180;
            var fill_rotation = rotation;
            var fix_rotation = rotation * 2;
            for (i in transform_styles) {
                $('.circle .fill, .circle .mask.full').css(transform_styles[i], 'rotate(' + fill_rotation + 'deg)');
                $('.circle .fill.fix').css(transform_styles[i], 'rotate(' + fix_rotation + 'deg)');
            }
        };

        var waypoint = new Waypoint({
            element: document.querySelector("#radial-progress"),
            handler: function (direction) {
                fill_circle();
            },
            offset: 600
        })
    }
});